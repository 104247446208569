
































































.ds-team {
  display: flex;
  flex-direction: column;
  &-avatar {
    width: 96px;
    height: 96px;
    margin-right: 20px;
  }
  &-card {
    border: 0;
    margin: 3% 0;
    width: 80%;
    text-align: left;
    align-self: flex-start;
    &:nth-child(even) {
      text-align: right;
      align-self: flex-end;
      /deep/ .el-card {
        &__body {
          display: flex;
          flex-direction: row-reverse;
          .ds-team-avatar {
            width: 96px;
            height: 96px;
            margin-left: 20px;
            margin-right: 0px;
          }
        }
      }
    }
  }

  &-des {
    color: white;
    line-height: 24px;
  }
  &-link {
    &::before {
      content: attr(href);
    }
  }
}
/deep/ .el-card {
  &__body {
    flex-direction: row;
    display: flex;
  }
}
